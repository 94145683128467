@import 'variables';

.btn {
  height: 32px;
  line-height: 14px;
  font-size: 14px;
}

.badge {
  line-height: 13px;
}

.toggle-btn-sm {
  height: 27px;
  line-height: 15px;
}

.badge {
  font-weight: 500;
}

.input-group-text {
  height: 32px;
  border-radius: .25rem 0 0 .25rem;
}

.ins-name {
  font-weight: 600;
  font-size: 16px;
}

.country-dropdown {
  width: 290px;
  z-index: 5555555;
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
}

.btn-outline-primary {
  border: 1px solid var(--primary);
  color: var(--primary);

  &:hover {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
  }
}

.btn-primary,
.k-button.k-primary,
.k-slider .k-draghandle {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);
}

.btn-group {
  label:last-child {
    margin-left: -1px;
  }
}


.btn-primary:hover {
  background-color: #585858;
  border: 1px solid #585858;
}

.btn-primary.focus,
.btn-primary:focus {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(22, 118, 156, 0.44);
}


.card {
  overflow: inherit !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--lgray-bg);
  opacity: 1;
  cursor: not-allowed;
}

.form-control {
  height: 32px;
  border: 1px solid var(--border-grid);
  border-radius: 5px;
  font-size: 14px;
  background-color: var(--white);
  color: var(--text-100);

}


.form-control-textarea {
  height: auto;
}

.k-input-inner {
  font-size: 14px;
}
.fs-14 { font-size: 14px !important;}

.table-bordered {
  border: 1px solid var(--border-lightgray);
}

.table-bordered td,
.table-bordered th {
  border: 1px solid var(--border-lightgray);
}

.table {
  color: var(--text-100);
}

.custom-checkbox {
  .custom-control-label {
    font-size: 14px;
  }

  cursor: pointer;

  input[type="checkbox"]:focus {
    outline: none;
    box-shadow: none;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
    border-width: 2px;
  }

  .custom-control-label::after {
    width: 20px;
    height: 20px;
  }

  .custom-control-label::before {
    top: 0;
  }

  .custom-control-label::after {
    top: 0px;
  }

  .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(153, 156, 158, 0.5);
    border-color: rgba(153, 156, 158, 0.5);
  }

  input:disabled {
    cursor: not-allowed;
  }
}

.c-radio {
  position: relative;

  .custom-control-label::before {
    top: 0.1rem;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: var(--white);
    border-color: var(--primary);
    background-color: var(--primary);
  }

  .custom-control-label::before {
    width: 22px;
    height: 22px;
    border: var(--border-mediumgray) solid 2px;
  }

  .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-size: 14px;
    background: var(--primary);
    border-radius: 20px;
    border: 3px solid var(--white);
  }

  .custom-control-label::after {
    top: 5px;
    left: -21px;
  }

  .custom-control-label {
    line-height: 27px;
  }
}

.dot-menu {
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-toggle {
    border: 0;
    background: transparent;
    display: flex;

    .material-symbols-outlined {
      font-size: 24px;
      color: #505860;
    }
  }

  .dropdown-menu {
    padding: 0.3rem;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    -webkit-animation-name: DropDownSlide;
    animation-name: DropDownSlide;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin: 0;
    border: none;
    font-size: $fs-normal;
    position: absolute;
    z-index: 1000;
    display: block;
    visibility: hidden;
    opacity: 0;
    color: var(--text-100);
    background-color: var(--white);
    border: 0;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transform: scale(1, 0);
    display: block;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    i {
      font-size: $fs-bigger;
      vertical-align: middle;
      margin-right: 5px;
      color: var(--text-80);
      font-weight: 600;
    }

    .dropdown-item:active {
      background-color: var(--lightgray-bg);
      color: var(--text-teal);
    }
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: var(--lightgray-bg);
  }

  .dropdown-menu.show {
    display: block;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    cursor: pointer;
  }
}

.navbar .dropdown-menu .dropdown-item, a {
  cursor: pointer;
}

.form-control:focus {
  border-color: var(--border-mediumgray) !important;
  background-color: var(--white) !important;
  box-shadow: none !important;
  color: var(--text-100) !important;
  outline: 0;
}

.dash-icons {
  .material-symbols-outlined {
    color: var(--primary);
    font-size: 40px;
  }
}

.k-switch-on:focus .k-switch-track,
.k-switch-on.k-focus .k-switch-track {
  outline: 0;
}

.k-switch-off:focus .k-switch-track,
.k-switch-off.k-focus .k-switch-track {
  border: 1px solid var(--border-grid);
  outline: 0;
}

.k-input:focus,
.k-input.k-focus {
  border-color: var(--border-mediumgray);
  box-shadow: none;
}

.modal-dialog {
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  min-height: calc(100% - 0.5rem);
}


.modal-content {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 10px;

  .card-main {
    background-color: var(--lightgray-bg);
    box-shadow: none;
    margin-bottom: 10px !important;

    .card-body {
      padding: 0.7rem;
    }
  }

  .card-header {
    background-color: var(--white);
    font-weight: 600;
    padding: 0.5rem;
    border-bottom: 1px solid var(--border-lightgray);
  }

  .custom-control-label {
    padding-left: 0;
  }
}

.modal-dialog-centered {
  min-height: calc(100% - 0.5rem);
}

.modal-centered {
  .modal-dialog {
    min-height: calc(100% - 0.5rem) !important;
  }
}

.modal-header {
  display: block;
  width: 100%;
  align-items: center;
  height: auto;
  margin: 0;
  padding: 12px 15px 10px;
  background-color: var(--white);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.modal-lg {
  max-width: 1200px;
  margin: 0 auto;
}

.modal-title {
  font-size: $fs-big;
  line-height: 1.1rem;
}

.modal-header {
  .close {
    margin: 0;
    padding: 0;
    color: var(--text-100);
  }

  button:focus {
    outline: 0;
  }
}

.modal-sm {
  max-width: 500px;
}

.modal-dialog {
  opacity: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: anim-open;
  animation-name: anim-open;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.modal.show .modal-dialog {
  -webkit-animation-name: anim-open;
  animation-name: anim-open;
}

.modal-body {
  padding: 0;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background-color: var(--lightgray-bg);
}

@-webkit-keyframes anim-open {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes anim-open {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes anim-close {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, -100%, 0);
  }
}

@keyframes anim-close {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

/* Inner elements animations */

@-webkit-keyframes anim-elem {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes anim-elem {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 1px;
}

.table {
  thead th {
    font-weight: 600;
  }
}



.list-group-item {
  padding: 0.75rem 1rem;
}

.text-dark {
  color: var(--text-100) !important;
}

.list-group {
  border: 0;

  .list-group-item {
    border: 0;
    padding: 0.45rem 0;
    border-bottom: 1px solid var(--border-lightgray);
    margin-bottom: 0;
    background-color: var(--white);
  }

  .list-group-item:last-child {
    border: 0;
  }

  i {
    font-size: 22px;
  }
}

.list-group-card {
  border: 0;

  .list-group-item {
    border: 0;
    padding: 0.45rem 0;
    border-bottom: 1px solid #f3f4f4;
    margin-bottom: 0;
  }

  .list-group-item:last-child {
    border: 0;
  }

  i {
    font-size: 22px;
  }
}


.list-no-border {
  .list-group-item {
    border: none;
    padding: 0.45rem 0.5rem;
    font-size: 14px;
  }
}

.if-no-label {
  margin-top: 34px;
}

.if-no-label-30 {
  margin-top: 30px;
}

.if-no-label-28 {
  margin-top: 28px;
}

label {
  margin-bottom: 0.3rem;
  color: var(--text-100);
  font-size: 14px;
}

.form-group {
  margin-bottom: 0.85rem;
  position: relative;
}

.table td,
.table th {
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  vertical-align: middle;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: white;
}


.radio-box-round {
  border: 1px solid #d5dee6;
  padding: 10px 10px;
  border-radius: 0.25rem;
  background: #fff;
}

.radio-box {
  border-radius: 5px;
  text-align: center;

  .custom-control {
    padding-left: 0;
  }

  input[type="radio"] {
    appearance: none;
  }

  border: 1px solid var(--primary);
  padding: 0 5px 0 5px;
  background: #fff;
  margin-top: 3px;

  .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    background-color: var(--primary);
    border-radius: 50px;
    width: 12px;
    height: 12px;
    top: 12px;
    left: -18px;
    transition: transform 0.3s;
    display: none;
  }

  .custom-control-label::after {
    transition: transform 0.3s;
    transform: translate(0, 0) scale(0);
    display: none;
  }

  .custom-control-label::before {
    width: 1.5rem;
    height: 1.5rem;
    top: 6px;
    display: none;
  }

  .custom-control-label {
    width: 100%;
    cursor: pointer;
    line-height: 20px;
    color: var(--primary);
    text-align: center;
    padding: 7px 0;
    margin-bottom: 0;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
  }
}

.radio-box-disable {
  opacity: 0.4;
}
.custom-disable {
  background: #eee;
}

.flood-que-align {
  min-height: 65px;
  position: relative;
}

.radio-box.selected {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);

  .custom-control-label {
    color: var(--white);
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: var(--white);
    border-color: var(--primary);
    background-color: var(--white);
    border-width: 2px;
    border-radius: 5px;
  }

  .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-color: var(--primary);
    transform: translate(0, 0) scale(1);
    display: none;
  }
}


@media screen and (min-width: 1025px) and (max-width: 1366px) {

  .table td,
  .table th {
    padding: 0.75rem 0.5rem;
  }


  .list-group-item {
    padding: 0.75rem 1rem;
  }

  .lap-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

::-webkit-input-placeholder {
  font-size: 14px;
}

::-moz-placeholder {
  font-size: 14px;
}

:-ms-input-placeholder {
  font-size: 14px;
}

:-moz-placeholder {
  font-size: 14px;
}

//kendo UI CSS
.k-combobox,
.k-datepicker {
  .k-button {
    border-radius: 0 4px 4px 0 !important;
  }
}

.k-grid td {
  overflow: inherit !important;
  border-color: var(--border-grid);
}

.k-checkbox:checked+.k-checkbox-label::before {
  border-color: var(--text-100);
  background-color: var(--text-100);
}

.k-checkbox-label::before,
.k-checkbox-label::after {
  font-size: 14px;
}

.k-checkbox-label::before {
  width: 18px;
  height: 18px;
}

.k-checkbox-label::after {
  width: 18px;
  height: 18px;
}

.k-checkbox-label {
  padding-left: calc(17px + 0.5rem);
  min-height: 10px;
  line-height: 17px;
  margin-left: 6px;
  font-size: 0.845rem;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected,
.k-list-container .k-button:active,
.k-list-container .k-button.k-state-active,
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-selected,
.k-spreadsheet-popup .k-button:active,
.k-spreadsheet-popup .k-button.k-state-active,
.k-spreadsheet-popup .k-button.k-state-selected,
.k-menu-group .k-item.k-state-selected,
.k-menu.k-context-menu .k-item.k-state-selected {
  background-color: var(--text-100);
}

.k-list .k-item.k-state-selected {
  margin: 5px 0;
}

.k-list .k-item:focus.k-state-selected,
.k-list .k-item.k-state-focused.k-state-selected,
.k-list-optionlabel:focus.k-state-selected,
.k-list-optionlabel.k-state-focused.k-state-selected {
  box-shadow: none;
}

.k-column-chooser-title,
.k-column-list-item {
  padding-right: calc(0rem + 16px);
}

.k-column-title {
  white-space: break-spaces;
}

.sk-folding-cube {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border-radius: 10px;
  padding: 50px;
}

.page-title {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  color: var(--text-100);
}

.k-toolbar,
.k-input,
.k-picker,
.k-expander-title,
label {
  font-family: 'Roboto', sans-serif !important;
}

.k-toolbar {
  padding: 3px 3px 3px 6px;
}

.k-grid {
  background-color: var(--white);
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;

  .btn {
    height: 24px;
    font-size: 13px;
  }

  a {
    color: blue !important;
    cursor: pointer;
  }

  .k-checkbox-label {
    padding-left: calc(8px + 0.5rem);
  }

  th {
    font-weight: 700;
    color: var(--text-100);
    font-size: 0.845rem;
    background-color: var(--white);
  }

  td {
    font-size: 14px;
  }

  td {
    border-width: 0 0 1px 1px !important;

    &:first-child {
      border-left-width: 0 !important;
    }
  }

  tr:nth-child(odd) {
    background-color: var(--white);
  }

  tr:last-child td:first-child {
    border-bottom-width: 1px;
    border-radius: 0;
  }

  tr:last-child td {
    border-bottom-width: 1px;
  }

  .k-grid-pager {
    border-color: var(--border-grid);
    background-color: var(--white);
    border-top: 0;

    .k-pager-numbers .k-state-selected,
    .k-pager-numbers .k-state-selected:hover {
      border-color: var(--white);
      background-color: var(--bg-sidebar);
      color: var(--text-80);
      border-radius: 3px;
    }

    .k-pager-wrap .k-pager-nav {
      color: var(--text-100);
    }

    .k-link {
      padding: 5px;
      font-size: 0.8rem;
      color: var(--text-80);
    }

    .k-state-disabled {
      color: var(--text-80);
    }

    .k-pager-info {
      font-size: $fs-small;
    }
  }

  .k-pager-wrap .k-link,
  .k-pager-wrap .k-pager-nav {
    border: none;
  }

  .k-checkbox-label {
    cursor: pointer;
  }
}

.k-pager-wrap {
  padding: 3px;
}

.modal-content {
  background-color: var(--white);
}

.modal-footer {
  border-top: 1px solid var(--border-lightgray);
}

.modal-header {
  border-bottom: 1px solid var(--border-lightgray);
}

.k-switch-off .k-switch-container {
  background-color: var(--border-lightgray);
}

.k-pager-wrap .k-link,
.k-pager-wrap .k-state-selected {
  width: calc(1 * (2px + 0.75rem + 1em));
  height: calc(1 * (2px + 0.75rem + 1.2em));
}

.k-dateinput-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-multiselect-wrap .k-input,
.k-numeric-wrap .k-input {
  height: 39px;
  padding: 0.25rem 0.75rem;
  font-size: 0.91rem;
  color: var(--text-100);
}

.k-datepicker .k-dateinput-wrap,
.k-datetimepicker .k-dateinput-wrap,
.k-timepicker .k-dateinput-wrap {
  border: 0;
  border-radius: 3px 0 0 3px;
}

.k-combobox .k-dropdown-wrap,
.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap {
  border-color: var(--border-grid);
  border-radius: 5px;
}

.k-autocomplete,
.k-combobox .k-dropdown-wrap,
.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap,
.k-dateinput .k-dateinput-wrap,
.k-textbox,
.k-textarea,
.k-input.k-textbox,
.k-multiselect-wrap,
.k-numerictextbox .k-numeric-wrap {
  border-color: var(--border-grid);
  border-radius: 5px;
}

.k-input {
  height: 32px;
  border-color: var(--border-grid);
}

.kmulti-select {
  .k-input {
    height: 40px;
    overflow-y: scroll;
    border-color: var(--border-grid);
  }
}

.k-dateinput-wrap .k-select,
.k-dropdown-wrap .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select {
  border-radius: 0 5px 5px 0 !important;
}

.k-dateinput-wrap .k-select,
.k-dropdown-wrap .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select {
  border-radius: 0;
}

.k-dropdown-wrap .k-clear-value,
.k-multiselect-wrap .k-clear-value {
  top: 7px;
}

.k-widget {
  font-size: 15px;
}

.k-popup .k-item {
  font-size: 13.5px;
}

.k-grid-header-wrap,
.k-grid-footer-wrap {
  border-radius: 5px 5px 0 0 !important;
}

.k-widget.k-state-invalid>.k-dateinput-wrap,
.k-widget.k-state-invalid>.k-dropdown-wrap,
.k-widget.k-state-invalid>.k-picker-wrap,
.k-widget.k-state-invalid>.k-multiselect-wrap,
.k-widget.k-state-invalid>.k-numeric-wrap,
.ng-invalid.ng-touched>.k-dateinput-wrap,
.ng-invalid.ng-touched>.k-dropdown-wrap,
.ng-invalid.ng-touched>.k-picker-wrap,
.ng-invalid.ng-touched>.k-multiselect-wrap,
.ng-invalid.ng-touched>.k-numeric-wrap,
.ng-invalid.ng-dirty>.k-dateinput-wrap,
.ng-invalid.ng-dirty>.k-dropdown-wrap,
.ng-invalid.ng-dirty>.k-picker-wrap,
.ng-invalid.ng-dirty>.k-multiselect-wrap,
.ng-invalid.ng-dirty>.k-numeric-wrap {
  border: 1px solid var(--border-lightgray);
}

.k-grid .k-grid-container {
  border-radius: 0 0 5px 0;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  height: 500px !important;
  background-color: var(--white) !important;
}

.k-grid-header,
.k-grid-toolbar,
.k-toolbar,
.k-editor .k-editor-toolbar,
.k-gantt-toolbar {
  background-color: var(--white);
  border-color: var(--border-grid);
  border-radius: 5px 5px 0 0;
}

.k-grid {
  border-color: var(--border-grid);
}

.k-grid th {
  font-weight: 600;
}

.k-grid th {
  padding: 0.65rem 0.5rem;
}

.k-grid td {
  padding: 0.32rem 0.5rem;
}

.k-grid td,
.k-grid .k-grid-content,
.k-grid .k-grid-header-locked,
.k-grid .k-grid-content-locked {
  border-color: var(--border-grid);
}

.k-pager-wrap .k-link,
.k-pager-wrap .k-pager-nav {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.k-grid .k-alt {
  background-color: var(--white);
}

.k-grid,
.k-grid .k-alt {
  color: var(--text-100);
}

.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
  color: var(--text-100);
  background-color: var(--border-lightgray);
}

.k-button,
.k-button-group.k-state-disabled .k-button:not(.k-state-active),
.k-button-group .k-button.k-state-disabled:not(.k-state-active),
.k-list-container .k-nodata .k-button,
.k-grouping-header .k-group-indicator,
.k-spreadsheet-filter,
.k-pivot-toolbar .k-button,
.k-fieldselector .k-list li.k-item,
.k-dropdowntree .k-dropdown-wrap,
.k-editor-toolbar a.k-tool,
.k-numerictextbox .k-select,
.k-handle,
.k-map .k-navigator,
.k-gantt-views li,
.k-task-single,
.k-scheduler-navigation .k-nav-today,
.k-scheduler-navigation .k-nav-prev,
.k-scheduler-navigation .k-nav-next,
.k-scheduler-views li,
.k-scheduler-footer li {
  border-color: var(--border-lightgray);
  background-color: var(--white);
  color: var(--text-100);
  border-radius: 5px !important;
  padding: 3px 5px !important;
}

.kendo-grid-100 {
  width: 100%;
  overflow-x: auto;
}

.k-button,
.k-button-group.k-state-disabled .k-button:not(.k-state-active),
.k-button-group .k-button.k-state-disabled:not(.k-state-active),
.k-list-container .k-nodata .k-button,
.k-dropdown .k-dropdown-wrap,
.k-grouping-header .k-group-indicator,
.k-spreadsheet-filter,
.k-pivot-toolbar .k-button,
.k-fieldselector .k-list li.k-item,
.k-colorpicker .k-picker-wrap,
.k-combobox .k-select,
.k-datepicker .k-select,
.k-timepicker .k-select,
.k-datetimepicker .k-select,
.k-dateinput .k-select,
.k-dropdowntree .k-dropdown-wrap,
.k-editor-toolbar a.k-tool,
.k-numerictextbox .k-select,
.k-handle,
.k-map .k-navigator,
.k-gantt-views li,
.k-task-single,
.k-scheduler-navigation .k-nav-today,
.k-scheduler-navigation .k-nav-prev,
.k-scheduler-navigation .k-nav-next,
.k-scheduler-views li,
.k-scheduler-footer li {
  border-color: var(--border-grid);
  color: var(--text-100);
  background-color: var(--white);
}

.k-autocomplete,
.k-combobox .k-dropdown-wrap,
.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap,
.k-dateinput .k-dateinput-wrap,
.k-textbox,
.k-textarea,
.k-input.k-textbox,
.k-multiselect-wrap,
.k-numerictextbox .k-numeric-wrap {
  color: var(--text-100);
  background-color: var(--white);
}



.k-tabstrip-top>.k-tabstrip-items .k-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: -1px;
  background: var(--bg-sidebar);

  .k-link {
    color: var(--text-lightgray);
  }
}

.k-tabstrip>.k-content {
  background-color: var(--white);
  border-color: var(--border-lightgray);
  color: var(--text-100);
}

.k-tabstrip-top {
  background-color: var(--white);

  .k-content.k-state-active {
    padding: 20px;
  }

  .k-state-active .k-link {
    color: var(--text-100) !important;
  }

  .k-tabstrip-items .k-link {
    padding: 0.55rem 1rem;
    display: list-item;
    text-align: center;
  }
}

.k-tabstrip-items .k-item:hover,
.k-tabstrip-items .k-item.k-state-hover {
  border-color: var(--border-mediumgray);
}

.k-tabstrip-top>.k-tabstrip-items .k-item:focus {
  outline: none;
}

.k-tabstrip-items {
  border-color: var(--border-lightgray)
}

.k-tabstrip-top .k-tabstrip-items .k-item.k-state-hover,
.k-tabstrip-top .k-tabstrip-items .k-item.k-state-active {
  border-bottom-color: var(--white);
  background: var(--white);
  box-shadow: none;
  outline: none;
  border-color: var(--border-lightgray);
  border-bottom: 0;
  font-weight: 600;
  border-radius: 5px 0 0;
}

.k-tabstrip-top .k-tabstrip-items .k-item.k-state-active:first-child {
  border-radius: 5px 0 0 0;
}

.k-tabstrip-top .k-tabstrip-items .k-item.k-state-active:last-child {
  border-radius: 0 5px 0 0;
}

.k-tabstrip-top .k-tabstrip-items .k-item.k-state-default:first-child {
  border-radius: 5px 0 0 0;
}

.k-tabstrip-top .k-tabstrip-items .k-item.k-state-default:last-child {
  border-radius: 0 5px 0 0;
}

.modal-body {
  overflow: auto;
  padding: 1rem 1rem;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  height: 100%;

  .card-body {
    padding: 0.5rem;
    margin-bottom: 20px;
  }
}

.modal-footer {
  justify-content: flex-end;
  background-color: var(--white);
}

.k-switch {
  font-size: 8px !important;
}

.k-switch-container {
  padding: 6px 10px;
}

.k-switch-label-on {
  left: 13px;
}

.k-switch-label-off {
  right: 13px;
  color: var(--text-100);
}

.k-switch-on:focus,
.k-switch-on.k-state-focused {
  box-shadow: 0 0 1px 2px var(--border-lightgray);
}

.k-switch-label-on,
.k-switch-label-off {
  display: block;
  font-size: 14px;

}

.k-switch-on .k-switch-container {
  background-color: var(--primary);
}

.k-switch-on:hover .k-switch-container,
.k-switch-on.k-state-hover .k-switch-container {
  background-color: var(--primary);
}

.form-check-input {
  margin-top: 0.2rem;
}

.k-label {
  margin-bottom: 0;
}

.k-input.k-invalid,
.k-input.ng-invalid.ng-touched,
.k-input.ng-invalid.ng-dirty {
  border-color: var(--border-grid);
}

@media (min-width: 1280px) and (max-width: 1366px) {

  .k-grid th,
  .k-grid td {
    padding: 0.5rem 0.5rem;
  }
}

.k-grid td.k-state-focused,
.k-grid td.k-focus,
.k-grid th.k-state-focused,
.k-grid th.k-focus,
.k-grid th:focus,
.k-grid .k-master-row>td:focus,
.k-grid .k-grouping-row>td:focus,
.k-grid .k-detail-row>td:focus,
.k-grid .k-group-footer>td:focus,
.k-grid .k-grid-pager.k-state-focused,
.k-grid .k-grid-pager.k-focus,
.k-grid .k-grid-pager:focus {
  box-shadow: none;
}

.k-popup,
.k-column-menu .k-menu:not(.k-context-menu),
.k-menu-group,
.k-menu.k-context-menu {
  border-color: var(--border-lightgray);
  color: var(--text-100);
  background-color: var(--white);
}

.k-input:focus-within {
  box-shadow: none;
  border-color: var(--border-mediumgray);
  background-color: transparent;
  box-shadow: none;
  color: var(--text-100);
}

.k-list .k-item:hover,
.k-list .k-item.k-state-hover,
.k-list-optionlabel:hover,
.k-list-optionlabel.k-state-hover,
.k-list-container .k-button:hover,
.k-list-container .k-button.k-state-hover,
.k-column-list-item:hover,
.k-columnmenu-item:hover,
.k-column-menu .k-menu:not(.k-context-menu) .k-item:hover,
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-hover,
.k-spreadsheet-filter-menu>.k-menu .k-item.k-state-hover,
.k-spreadsheet-filter-menu>.k-menu:not(.k-context-menu) .k-item.k-state-hover,
.k-spreadsheet-popup .k-button:hover,
.k-spreadsheet-popup .k-button.k-state-hover,
.k-menu-group .k-item:hover,
.k-menu-group .k-item.k-state-hover,
.k-menu.k-context-menu .k-item:hover,
.k-menu.k-context-menu .k-item.k-state-hover {
  color: var(--text-100);
  background-color: var(--bg-sidebar);
}

.k-calendar .k-calendar-navigation {
  color: var(--text-100);
  background-color: var(--bg-sidebar);
  box-shadow: inset -1px 0 var(--bg-sidebar);
}

.k-calendar-navigation::before,
.k-calendar-navigation::after {
  box-shadow: 0 0 6em 3em var(--bg-sidebar);
}

.k-content,
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper,
.k-editor,
.k-listbox .k-list-scroller,
.k-scheduler-agendaview tr.k-state-hover .k-scheduler-datecolumn,
.k-scheduler-agendaview tr:hover .k-scheduler-datecolumn,
.k-scheduler-agendaview tr:hover .k-scheduler-groupcolumn,
.k-notification {
  color: var(--text-100);
  background-color: var(--white);
}

.k-calendar-infinite .k-calendar-view::after,
.k-calendar-infinite .k-calendar-monthview::after {
  box-shadow: 0 0 40px 20px var(--white);
}

.k-calendar {
  color: var(--text-100);
  background-color: var(--white);
}

.k-calendar .k-calendar-navigation-highlight {
  color: var(--text-100);
  background-color: var(--white);
}

.k-grid-pager {
  color: var(--text-80);
}

.k-pager-wrap .k-link:hover,
.k-pager-wrap .k-link.k-state-hover,
.k-pager-wrap .k-pager-nav:hover,
.k-pager-wrap .k-pager-nav.k-state-hover {
  background-color: var(--bg-sidebar);
}

.k-grid-header .k-header>.k-link {
  overflow: inherit;
  text-overflow: inherit;
  white-space: break-spaces;
}


.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-desc-sm,
.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-asc-sm {
  margin-left: 0px;
}

.k-expander {
  font-family: "Inter";
  border-radius: 5px;
}

.k-expander-title {
  text-transform: inherit;
  font-weight: normal;
  color: var(--text-100);
}

.k-expanded {
  .k-expander-title {
    font-weight: 500;
  }

  .k-expander-header {
    background-color: var(--lgray-bg);
  }
}

.k-expander-header {
  padding: 0.5rem 1rem;

}

.k-expander.k-state-focus,
.k-expander.k-focus {
  box-shadow: none;
}

//new grid classes
.k-grid .k-grid-header-sticky .k-header {
  border-bottom-color: var(--border-grid);
}

.k-grid .k-grid-header-sticky,
.k-grid .k-grid-content-sticky,
.k-grid .k-grid-header-sticky .k-header,
.k-grid .k-grid-content-sticky td {
  border-color: var(--border-grid);
  overflow: initial !important;
}

.dropdowns-opened tbody tr.non-dropdown td {
  z-index: 0;
}

kendo-grid .k-alt .k-grid-content-sticky,
kendo-grid .k-grid-row-sticky.k-alt td {
  background-color: #fff;
}

.rg-cliam-search-grid {
  .k-grid-content {
    height: calc(100vh - 300px);
  }
}

.k-grid .k-grid-header-sticky,
.k-grid .k-grid-content-sticky,
.k-grid .k-grid-header-sticky .k-header,
.k-grid .k-grid-content-sticky td {
  border-color: var(--border-grid);
}

.k-grid-header .k-grid-header-sticky,
.k-grid-header .k-grid-header-sticky.k-sorted {
  background-color: #fff;
}

//bootstrap
.accordion-button:not(.collapsed) {
  color: var(--text-100);
  background-color: var(--whiet);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.accordion-button {
  padding: 10px;
  font-weight: 600;
}

.k-grid-md .k-table-th>.k-cell-inner>.k-link {
  font-weight: 500;
}

//custom
.recent-quote-grid {
  .k-grid-content {
    max-height: 235px;
  }
}

.optis-grid {
  .k-grid-content {
    min-height: 235px;
  }
}

.underwriter-repeater {
  border-bottom: 1px solid var(--border-lightgray);
  padding: 8px 0;

  &:last-child {
    border-bottom: 0;
    padding: 8px 0 0;
  }
}

.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


.validation-msg-t-17 {
  background-color: var(--danger);
  position: absolute;
  padding: 0 10px;
  font-size: 11px;
  color: var(--white);
  border-radius: 3px;
  top: -17px;
  right: 22px;
  @extend .one;
  @extend .animate;
  @extend .fadeInUp;
  z-index: 555;
}

.validation-msg {
  background-color: var(--danger);
  position: absolute;
  padding: 0 10px;
  font-size: 11px;
  color: var(--white);
  border-radius: 3px;
  top: -7px;
  right: 22px;
  @extend .one;
  @extend .animate;
  @extend .fadeInUp;
  z-index: 555;
}

.validation-msg-label {
  @extend .validation-msg;
  top: 16px;
  z-index: 555;
}

.validation-msg-label-t9 {
  @extend .validation-msg;
  top: 9px;
  z-index: 555;
}

.validation-msg-radio {
  top: -16px;
  right: -1px;
  z-index: 555;
}

.validation-radio {
  top: auto;
  right: auto;
  left: auto !important;
  position: relative;
  height: 17px;
  width: fit-content !important;
}

.footer-main {
  padding: 10px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  p {
    font-size: 12px;
    color: #505860;
  }
}

.bg-light-info {
  background-color: #86b7fe5e !important;
}
.bg-red{
  background-color: #f34538ee !important;
  color: #fff;
}
.bg-green{
  background-color: #449b21fa !important;
  color: #fff;
}
.bg-orange{
  background-color: #ffa500 !important;
}
.bg-yellow{
  background-color: #ff9 !important;
}
.bg-pink{
  background-color: #fcf !important;
}
.bg-darkgray{
  background-color: #a9a9a9 !important;
}
.bg-white {
  background-color: #fff !important;
}

.live-status {
  color: var(--green);
  font-weight: 600;
}

.modal-700 {
  max-width: 700px;
}

.container-main {
  min-height: calc(100vh - 70px);
}
.border-custom {
  border: 2px solid red; /* You can customize the color and thickness */
  border-radius: 4px;   /* Optional: Add some rounding if needed */
  padding: 4px;         /* Optional: Adjust padding for better spacing */
}
.referal-realson-list {
  background-color: rgb(254 228 134);
  padding: 5px 10px;
  border-radius: 4px;
}
.referal-realson-list-red {
  background-color:#f8d7da;
  padding: 5px 10px;
  border-radius: 4px;
}

.main__card {
  margin-inline: auto;
  max-width: 22em;
  margin-top: 0;
}

.card-animation {
  color: white;
  background-color: #f1c927;
  position: relative;
  width: 100%;
  padding: 8px 15px;
  overflow: hidden;
  box-shadow: none;
  border: 0;
  color: #000;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}

.card-animation__line {
  position: absolute;
  animation: 3s 3s linear infinite;
}

.card-animation__line_left {
  animation-name: to-top;
  background: linear-gradient(to bottom, #000, transparent);
  left: 0;
  bottom: -100%;
  width: 4px;
  height: 100%;
}

.card-animation__line_right {
  animation-name: to-bottom;
  background: linear-gradient(to bottom, transparent, #000);
  right: 0;
  top: -100%;
  width: 4px;
  height: 100%;
}

.card-animation__line_top {
  animation-delay: 1.5s;
  animation-name: to-right;
  background: linear-gradient(to right, transparent, #000);
  width: 100%;
  height: 4px;
  top: 0;
  left: -100%;
}

.card-animation__line_bottom {
  animation-delay: 1.5s;
  animation-name: to-left;
  background: linear-gradient(to right, #000, transparent);
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 4px;
}

@keyframes to-top {
  to {
      bottom: 200%;
  }
}

@keyframes to-bottom {
  to {
      top: 200%;
  }
}

@keyframes to-left {
  to {
      right: 200%;
  }
}

@keyframes to-right {
  to {
      left: 200%;
  }
}
.cursor-none{
  cursor: none !important;
}

.padd-lr-10 { padding-left: 10px !important; padding-right:10px !important;}
  .announcement-focus {
    position: relative;
    overflow: hidden;
  }
  .pp-animation {
    color: white;
    background-color:transparent;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height:100%;
    z-index:0;
  }
 
  .pp-animation__line {
    position: absolute;
    animation: 3s 3s linear infinite;
  }
 
  .pp-animation__line_left {
    animation-name: to-top;
    background: linear-gradient(to bottom,  #ffa017, #814b00);
    left: 0;
    bottom: -100%;
    width: 4px;
    height: 100%;
  }
 
  .pp-animation__line_right {
    animation-name: to-bottom;
    background: linear-gradient(to bottom, #814b00,  #ffa017);
    right: 0;
    top: -100%;
    width: 4px;
    height: 100%;
  }
 
  .pp-animation__line_top {
    animation-delay: 1.5s;
    animation-name: to-right;
    background: linear-gradient(to right, #814b00,  #ffa017);
    width: 100%;
    height: 4px;
    top: 0;
    left: -100%;
  }
 
  .pp-animation__line_bottom {
    animation-delay: 1.5s;
    animation-name: to-left;
    background: linear-gradient(to right,  #ffa017, #814b00);
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 4px;
  }

  .npd-container .accordion-button{
    background-color: var(--primary);
    padding:6px 10px;
    color: var(--white);
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 10px 0;
    border-radius: 5px 5px 0 0 !important;
}
.npd-container .accordion-body { padding-top: 0;}
.npd-container .accordion-button { position: relative;}
.npd-container .accordion-button:after {
  content: "\e69b";
  font-family: "Material Symbols Outlined";
  font-size: 29px;
  font-weight: 100;
  transform: rotate(180deg) !important;
  background: none !important;
  width: 29px;
  height: 29px;
  line-height: 29px;
  position: absolute;
  right: 12px;
  top: 0;
  transition:all ease .4s;
}
.npd-container .accordion-button.collapsed:after {
  transform: rotate(0deg) !important;
  transition:all ease .4s;
}
.navbar-light .navbar-toggler { padding: 0; margin-right: 5px}


.hamburger:hover{
  cursor: pointer;
}

.navbar-toggler:focus, .navbar-toggler:focus-visible, .navbar-toggler{ outline: 0 !important; border: 0 !important; color: transparent !important;}
.hamburger .line:nth-child(2){
  opacity: 0;
}

.hamburger .line:nth-child(1){
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}
.hamburger .line:nth-child(3){
  -webkit-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -o-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}
.navbar-toggler .hamburger .line{
  width: 32px;
  height: 3px;
  background-color:var(--primary);
  display: block;
  margin:7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-toggler.collapsed .hamburger .line:nth-child(2){
  opacity: 1;
}

.navbar-toggler.collapsed .hamburger .line:nth-child(1){
  -webkit-transform: translateY(0) rotate(0);
  -ms-transform: translateY(0) rotate(0);
  -o-transform: translateY(0) rotate(0);
  transform: translateY(0) rotate(0);
}
.navbar-toggler.collapsed .hamburger .line:nth-child(3){
  -webkit-transform: translateY(0) rotate(0);
  -ms-transform: translateY(0) rotate(0);
  -o-transform: translateY(0) rotate(0);
  transform: translateY(0) rotate(0);
}

@media (max-width:767px) {
  .occupation-btn { margin-top:15px;}
}
 
  @keyframes to-top {
    to {
        bottom: 200%;
    }
  }
 
  @keyframes to-bottom {
    to {
        top: 200%;
    }
  }
 
  @keyframes to-left {
    to {
        right: 200%;
    }
  }
 
  @keyframes to-right {
    to {
        left: 200%;
    }
  }
  .password-input-wrapper {
    position: relative;
  }
  
  .password-input-wrapper .glyphicon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
  }
.password-icon {
  position: absolute;
  top:30px;
  right:20px
}
.changePass-group .form-control{ background-image:none !important;}
.password-validation-rules {
  position: absolute;
  right: -265px;
  top: 10px;
}

.tooltip-content { display: none;}
.tooltip-hover:hover + .tooltip-content { display: block;}
.validation-msg-label.nested-msg-label{
  top: -9px;
  margin-left: 0;
  border-radius: 3px !important;
}
.validation-msg-label.nested-msg-label-right{
  top: -9px;
  margin-left: 0;
  border-radius: 3px !important;
  right:9px
}

.policyAct-tab .k-tabstrip{ padding: 15px;}
.policy-box.policyAct-tab .data-header { 
    background-color: var(--primary);
    padding:3px 10px;
    color: var(--white);
    font-size: 15px;
    font-weight: 500;
    margin: -15px -15px 15px -15px;
    border-radius: 5px 5px 0 0;
}
.policy-box .data-header-tomato { 
  background-color:tomato;
  padding:3px 10px;
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  margin: -15px -15px 15px -15px;
  border-radius: 5px 5px 0 0;
}

.policyAct-tab .backBtn {position: relative; font-weight: 500; padding: 0; background: transparent !important;
border:0 !important; outline:none !important;}
.policyAct-tab .backBtn:after {
  content: "";
  content: "";
  position: absolute;
  left: -13px;
  top: 10px;
  width: 10px;
  height: 10px;
  border: 0.14rem solid #fff;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(-45deg);
  border-radius: 1px;
}

.bi-box .row1{ padding-right: 12px;}

.AddEditUser-body .modal-body, .AddEditUser-body .k-tabstrip-content { 
  overflow: inherit !important;
  height: inherit !important;
  max-height: inherit !important;
  }
.overflow-inherit {
  overflow: inherit;
}
.mobile-ddmenu .iti{
  display: block
}
.business-ddmenu .pca{ 
  height: inherit !important;
  position: absolute !important;
  width: 100%;
  top: 100% !important;
  z-index: 557 !important;
}
.lbl-two {
  color: green;
}
.small-label {
  top: -4px;
  position: relative;
  font-size: 13px;
}
.k-animation-container { 
  z-index: 9999 !important;
} 
.po-column { 
  height: 60px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.agentPolicyHandler-bg {
  background-color: var(--primary);
  color: #fff !important;
}
.claimBg .claimDashboard-row, .claimDashboard-row  {
  background-color:#ED2E36;
}
.claimBg {
  background-color: #FFF125;
}
.form-check-input.referinfo-text { height:inherit}
.k-popup.k-list-container.k-multiselect-popup > label {padding: 2px 16px 0;}
.k-popup.k-list-container.k-multiselect-popup label input[type='checkbox'] {
  position: relative;
  top:1px
}

// .k-list-item.k-selected, .k-selected.k-list-optionlabel {
//   background: #e4e7eb;
// }
// .k-list-item.k-selected:hover, .k-selected.k-list-optionlabel:hover, 
// .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel {
//   background: #b7b9bd;
// }
.product-name-label {
    font-size: 14px;
    color: #ffffff;
    background: #04508c;
    padding: 4px 6px;
    border-radius: 5px;
    font-weight: 500 !IMPORTANT;
}
.k-grid td.text-truncate{
  overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.info-tt-icon {
  position: relative;
  top:6px;
  font-size: 22px;
  color: #2f5093;
}
.bdc-three-inner .info-tt-icon, .bdc-four-inner .info-tt-icon, .bdc-seven-inner .info-tt-icon{ 
  position: absolute !important; top:0
}
.tooltip { min-width:650px !important; width:650px !important}

.activeBlock {border: 2px solid #cc0f16;}

.breakDown-popup .border-right { border-right-width: 2px !important;}
.breakDown-popup .base td:nth-child(4){ 
  border-right-width: 2px !important;
}
.breakDown-popup .modal-dialog{max-width: 1024px;}

.opennewtab-icon {
  font-size: 21px;
  font-weight: bold;
  position: relative;
  top: 6px;
  left: 6px;
  color: #04508c;
}
.validation-phone .iti {
  display: block;
}


.btn, .form-control{
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", 
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.adsubcover-popup .modal-700 {
  max-width: 1024px;
}
.disableControl{
  height: 31px;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.enableControl{
  display: none;
}

.actionButton { width:100% !important}
.actionButton.mtaquote-single { width:25% !important}
.btn-common-length {
    width: 50%;
    margin: 0 auto;
}
.tp-text-color {
  color:#cc0f16;
}


.inputarea{
  margin-top: 56px;
}

.form-check-copy {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
}

.form-check-label-copy {
  margin-right: 10px;
  font-size: 16px;
  margin-top: 7px;
}

.sof-description {
  padding: 15px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.sof-description ul {
  list-style-type: disc;
  padding-left: 20px;
}

.sof-description li {
  margin-bottom: 8px;
}

.bankdetails-lbl { min-width: 105px;}
.sn-width {width: 65px !important; text-align: center;}
.validation-msg-label-twl { top:37px}

.Premium_Select {
  font-weight: bold;
  font-size: 15px;
  color: #CC0F16;
}

.policyhistory-childgrid .k-grid .k-table-th{ background: #eee; }
.policyhistory-childgrid .k-grid .k-table-th, .policyhistory-childgrid .k-grid td { padding: 0.3rem 0.5rem !important;}
.mt-33 { margin-top: 33px}
.mb-33 { margin-bottom: 33px}
.addclaim-bg { background: rgba(0, 0, 0, 0.03);}
.modal-backdrop.bdc-six, .modal-backdrop.bdc-seven, .modal-backdrop.TransactionDetailComponent  {z-index: 1067 !important}
.modal-on-modal-dialog.bdc-six-inner, .modal-on-modal-dialog.bdc-seven-inner, .modal-backdrop.ap-backdrop, .modal.editClaim-modal,
.modal-backdrop.TransactionDetailComponent + .modal   { z-index: 1068 !important}
.modal-backdrop.ap-backdrop, .modal-on-modal-dialog.ap-modal, .modal-backdrop.editClaim-backdrop  {z-index: 1067 !important}
.tooltip > div { color:#fff !important; padding: 5px 5px; font-size: 14.5px; text-align: left;}
.tooltip > div h6 { color:#fff !important; font-size: 14.5px; text-align: left; margin:0 0 7px}
.tooltip { z-index: 999999!important}
.towncity-ddmenu form > div { position: relative;}
.towncity-ddmenu .pca { top:100% !important}
.modal-body .country-dropdown .iti__country-list{ max-height: 110px !important;}
.form-control.fileChoose { text-indent: 0;}
.consent-modal {max-width: 700px;background: #fff;border: 1px solid rgba(0, 0, 0, .2);width: 100%;margin: 0;position: absolute;left: 0;top: 0;height: auto;
  z-index: 9999; -moz-border-radius: 5px;-webkit-border-radius: 5px;border-radius: 5px; }
.consent-modal h6 { font-size: 20px; margin: 0;color: var(--white);font-size: 15px;font-weight: 500;
-moz-border-radius: 5px 5px 0 0-webkit-;border-radius: 5px 5px 0 0;border-radius: 5px 5px 0 0;}
.cm-header {padding:7px 15px;background-color: var(--primary); }
.consent-modal p {padding: 15px;}
.bankstatement-grid .k-grid td.bs-td-color { background:var(--lightgray-bg)}
// .scollbody-tbl .scrollbar-content{
//   width:72%;
//   overflow-x: scroll !important;
// }
.scollbody-tbl .k-table td:nth-child(1), .scollbody-tbl .k-table th:nth-child(1)  { width: 9.5% !important;}
.scollbody-tbl .k-table td:nth-child(2), .scollbody-tbl .k-table th:nth-child(2) { width: 10.5%  !important;}
.scollbody-tbl .k-table td:nth-child(3), .scollbody-tbl .k-table th:nth-child(3) { width: 77%  !important;}
.scollbody-tbl .k-table td:nth-child(4), .scollbody-tbl .k-table th:nth-child(4){ width: 3% !important;}


.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(1),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(1)  { width: 7% !important;}
.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(2),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(2)  { width: 10% !important;}
.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(3),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(3) { width: 16% !important;}
.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(4),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(4)  { width: 6% !important;}
.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(5),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(5)  { width: 7% !important;}
.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(6),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(6)  { width: 7% !important;}
.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(7),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(7)  { width: 6% !important;}
.scollbody-tbl .k-table td.scrollbar-content .k-table td:nth-child(8),
.scollbody-tbl .k-table td.scrollbar-content .k-table th:nth-child(8)  { width: 41% !important;}

.scollbody-tbl .k-table, .scrollbar-content .k-table  { table-layout: unset !important;}

.scollbody-tbl .k-table td,
.scollbody-tbl .k-table th,
.scollbody-tbl .k-table td.scrollbar-content .k-table td,
.scollbody-tbl .k-table td.scrollbar-content .k-table th {
  word-break: break-word;
}
.success-border {
  border:  solid green;
}

.scollbody-tbl .scrollbar-content .k-grid  { width:100% !important }
.scollbody-tbl .k-grid-header-wrap > table th,.scollbody-tbl > table th  { background: var(--bs-secondary); color: var(--white);}
.scollbody-tbl .scrollbar-content table th { background-color: var(--lightgray-bg); color: var(--text-100);}

.brokerrecohis-grid .k-column-title { white-space: inherit;} 
.aged-debtor-payment-detail .modal-lg{
  max-width:calc(100% -  30px) !important;
}
.remittance-receipt-tbl table th{ background-color: #000; color: #fff;}
.kendoui-borderbox table td, .kendoui-borderbox table th  { box-sizing: border-box !important}
.greenbg-op-20{ background: #19875420;}
.redbg-op-20{ background: #f2dede;}
.mentioned-cr-tbl { border:0}
.mentioned-cr-tbl td, .mentioned-cr-tbl td{padding: 5px 5px; border-bottom: 1px solid var(--border-lightgray);}
.pl-38 { padding-left: 38px !important;}
.h-border td { border-bottom: 2px solid #000 !important }
.mentioned-cr-tbl.nesing td, .mentioned-cr-tbl.nesing td{ font-size:12px; padding: 0.2rem 0.5rem !important;}
.blankpage-height { min-height: 495px;}
.sr-badge .k-chip {
  height: 22px;
  font-size: 11px;
  padding: 5px 7px 5px 3px!important;
  line-height: 1 !important;
  position: relative;
  top: -2px;
}
.sr-badge .k-chip-actions {
  width: 16px;
    height: 11px;
    margin: 0 !important;
}
.sr-badge.k-chip-md .k-chip-action {
  padding: 0.15rem;
}
.sr-badge .k-input {
  overflow-y: auto;
}
.sr-badge .k-chip-content { margin:0; padding: 0;}
.sr-digit-tbl tr td, .sr-digit-tbl tr th { font-size: 14px !important; line-height: 1.2; padding: 3px 5px !important;}
.scollbody-tbl-new .scrollbar-content {border-radius: 5px; overflow-y: hidden; }
.scollbody-tbl-new .scrollbar-content > table {border-right:1px solid #dee2e6}
.scollbody-tbl-new .scrollbar-content > table td:nth-child(1), 
.scollbody-tbl-new > .scrollbar-content > table  th:nth-child(1){ width: 9.5% !important; padding: .5rem;}
.scollbody-tbl-new  .scrollbar-content > table td:nth-child(2), 
.scollbody-tbl-new > .scrollbar-content > table th:nth-child(2){ width: 10.5%  !important; padding: .5rem;}
.scollbody-tbl-new  .scrollbar-content > table td:nth-child(3), 
.scollbody-tbl-new  > .scrollbar-content > table th:nth-child(3){ width: 77%  !important; padding: .5rem;}
.scollbody-tbl-new >.scrollbar-content > table td:nth-child(4), 
.scollbody-tbl-new  > .scrollbar-content > table th:nth-child(4){ width: 3% !important; padding: .5rem;}
.scollbody-tbl-new > .scrollbar-content > table  th {background: var(--bs-secondary);color: var(--white); 
  border-right:1px solid #dee2e6; font-size:13.5px;}
.scollbody-tbl-new > .scrollbar-content > table  th:last-child { border-right:0}


.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(1),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(1)  { width: 7% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(2),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(2)  { width: 10% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(3),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(3) { width: 16% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(4),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(4)  { width: 6% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(5),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(5)  { width: 7% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(6),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(6)  { width: 7% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(7),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(7)  { width: 6% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td:nth-child(8),
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th:nth-child(8)  { width: 41% !important; padding: .5rem;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table td, .scollbody-tbl-new .scrollbar-content > table td, .trans-tbl table th, .trans-tbl table td  {
  border-right:1px solid #dee2e6;
font-size:13.5px; border-bottom:1px solid #dee2e6;}
.text-blue { color:blue;}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table th, .trans-tbl table th{background-color: var(--lightgray-bg);color: var(--text-100)}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content .inner-tbl, .trans-tbl .inner-tbl { border: 1px solid #dee2e6; border-radius: 5px}
.scollbody-tbl-new .scrollbar-content > table td.scrollbar-content table .tbl-totalrow  td { border:0; border-top:2px solid #333;
  border-bottom:2px solid #333; border-right: 0; font-weight: bold; } 
.propdet-po-content label .info-tt-icon { position: absolute !important; top:0}

.trans-tbl table th, .trans-tbl table td { padding: 0.5rem;}


.viewPolicy-popup .modal-lg{
  max-width: 1440px;
}

.headerTextCenter .k-link{ justify-content: center !important;}
.thead-dark { }
.tbl-layoutAuto .k-grid .k-grid-header .k-table { table-layout: auto;}
.th-head-middle .k-grid-md .k-table-th { vertical-align: middle;}
.binder-bg table th {
  background-color:  #FFA500;
  color: white;
}

@media only screen and (max-width:1023px) {
  .scollbody-tbl-new .scrollbar-content > table {width: 1200px;}
}
@media only screen and (max-width:767px) {
  .addclaim-bg{ width: 100%!important}
}
.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}