/* You can add global styles to this file, and also import other style files */
@import "assets/styles/custom-bootstrap";

body {
    font-family: "Roboto", sans-serif;
    background-color: var(--body-bg);
    color: var(--text-100);
    font-size: 14px;
}

.text-primary {
    color: var(--primary) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-100);
}

.cursor-pointer {
    cursor: pointer;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px;
}

.font-weight-semibold {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}
.navbar {
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: var(--white);
    padding-left: 10px;

    .dropdown-menu-end {
        right: 0;
    }

    .form-control {
        background-color: var(--body-bg) !important;
    }

    .dropdown-toggle::after {
        content: "\e5cf";
        font-family: "Material Symbols Outlined";
        border: 0;
        position: absolute;
        right: -5px;
    }

    .navbar-brand {
        img {
            max-height: 40px;
        }
    }

    // .dropdown:hover .dropdown-menu {
    //     display: block;
    //     margin-top: 0;
    // }

    .dropdown-menu {
        color: var(--text-100);
        padding: 0.5rem;
        border: 0;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.09);
        transition: all 0.5s;
        border-radius: 0 0 10px 10px;
        clip-path: inset(0px -10px -10px -10px);
        border-top: 1px solid #e0e0e0;

        .dropdown-item {
            color: var(--text-80);
            padding: 0.5rem 1rem;

            &:hover {
                border-radius: 5px;
            }
        }
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: var(--text-100);
        text-decoration: none;
        background-color: var(--border-grid);
    }

    .dropdown-mega {
        .dropdown-menu {
            width: 100%;
            left: 0;
        }

        h5 {
            color: var(--primary);
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        .list-group-item {
            position: relative;
            display: block;
            padding: 0.35rem 1rem 0.35rem 0;
            color: var(--text-80);
            text-decoration: none;
            background-color: var(--white);
            border: 0;
            cursor: pointer;
            font-size: 15px;

            &:hover {
                color: var(--primary);
            }
        }
    }

    .navbar-nav {
        .nav-link {
            display: block;
            padding: 20px 0.75rem 20px 0.75rem;
            color: var(--text-100);
            position: relative;
            cursor: pointer;
            font-size: 15px;

            &:hover {
                color: var(--primary);
            }

            &:focus {
                color: var(--primary);
            }
        }
    }

    @media (min-width: 200px) {
        .animate {
            animation-duration: 0.3s;
            -webkit-animation-duration: 0.3s;
            animation-fill-mode: both;
            -webkit-animation-fill-mode: both;
        }
    }

    @keyframes slideIn {
        0% {
            transform: translateY(1rem);
            opacity: 0;
        }

        100% {
            transform: translateY(0rem);
            opacity: 1;
        }

        0% {
            transform: translateY(1rem);
            opacity: 0;
        }
    }

    @-webkit-keyframes slideIn {
        0% {
            -webkit-transform: transform;
            -webkit-opacity: 0;
        }

        100% {
            -webkit-transform: translateY(0);
            -webkit-opacity: 1;
        }

        0% {
            -webkit-transform: translateY(1rem);
            -webkit-opacity: 0;
        }
    }

    .slideIn {
        -webkit-animation-name: slideIn;
        animation-name: slideIn;
    }
}

.card {
    overflow: hidden;
    margin-bottom: 0;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow:
        rgb(0 0 0 / 10%) 0px 1px 3px 0px,
        rgb(0 0 0 / 6%) 0px 1px 2px 0px;

    .card-body {
        padding: 20px;
    }

    .card-footer {
        padding: 0.25rem 0.75rem 0.75rem 0.75rem;
        background-color: var(--white);
        border-top: none;
    }
}

.overflow-scrollable
{
    overflow:scroll;
    max-height: 385px;
}

.small-text {
    font-style: italic;
    font-size: 13px;
    color: var(--text-80);
}

.policy-box {
    border: 1px solid var(--border-lightgray);
    border-radius: 5px;
    padding: 15px;

    .data-header {
        background-color: var(--primary);
        padding: 5px 10px;
        color: var(--white);
        font-size: 15px;
        font-weight: 500;
        margin: -15px -15px 10px -15px;
        border-radius: 5px 5px 0 0;
    }
}

.tbl-insurere-offer {
    .offer-amount {
        font-size: 18px;
        font-weight: 600;
        padding: 0;
    }
}

.insurer-logo {
    max-height: 110px;
    border-radius: 10px;
}

//dialog
.quote-details-dialog {
    .modal-lg {
        max-width: 96%;
    }
}

.modal-on-modal {
    z-index: 1066 !important;
}

.modal-on-modal-dialog {
    z-index: 1067 !important;
}

.card-gray {
    background-color: var(--lightgray-bg);
}

.input-without-lbl-gray {
    .gray-input-box {
        margin-bottom: 10px;
        padding: 6px 12px;
        border-radius: 5px;
        background-color: var(--lightgray-bg);
        align-items: center;

        label {
            margin-bottom: 0;
        }
    }
}

input[type="file"] {
    text-indent: -999em;
    outline: none;
    width: fit-content;
    position: absolute;
    cursor: pointer;
    width: auto;
    left: auto;
    height: auto;
    top: auto;
}

.input-doc-upload {
    input[type="file"] {
        text-indent: -999em;
        outline: none;
        width: fit-content;
        position: absolute;
        cursor: pointer;
        width: 68px;
        left: -1px;
        height: 35px;
        top: -1px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
    }
}

.dropzone .file-label {
    font-size: 1.2em;
}

.file {
    color: var(--text-100);
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    background: var(--white);
    border-radius: 5px;
    padding: 5px 15px;

    .file-input {
        cursor: pointer;
    }
}

.fr-box.fr-basic .fr-element {
    height: calc(100vh - 365px);
}

//file upload
.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 200;
    height: auto;
    border: 2px dashed var(--primary);
    border-radius: 5px;
    background-color: #eeedef;
    margin: 10px 0;
    width: 100%;
    padding: 30px;

    .drop-zone-text {
        font-size: 15px;
        font-weight: 500;
        margin-top: 5px;
        color: var(--text-100);
    }

    .btn-link {
        font-weight: 500;
    }

    h3 {
        font-size: 15px;
        padding-top: 5px;
        font-weight: normal;
    }

    img {
        max-height: 32px;
    }
}


.input-without-lbl-gray .gray-input-box {
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 5px;
}

.gray-input-box {
    background-color:var(--lightgray-bg);
    padding: 5px 10px;
    align-items: center;

    label {
        margin-bottom: 0;
    }
}

.announcement-grid {
    .k-grid-content{
        height:calc(100vh - 375px);
    }
}

.light-gray-list {
    background-color: rgb(235 235 235);
    padding: 5px 10px;
    border-radius: 4px;
  }

.pt-27 {
    padding-top: 27px;
  }

  .custom-row-class-bg-yellow {
    background-color: rgba(255, 240, 27, 0.555) !important; /* Or any other styling you want */
    color: black;
  }

  .custom-row-class-bg-lightgray {
    background-color: var(--lightgray-bg) !important; /* Or any other styling you want */
  }
  
.custom-row-class {
    background-color: var(--primary)!important; 
    color: white;
    font-weight: bold;
  }
  .custom-row-class-bg-black {
    background-color: var(--lightgray-bg) !important;
    color:#000;
    font-weight: bold;
  }

  .k-grid tr.newdate td {
    border-top: 2px dashed darkgrey !important;
}

.toolbar-primary .k-toolbar{
    background-color: var(--primary);
}